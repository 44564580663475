import React, { useEffect, useState } from 'react'
import Breadcrumbs from "components/Common/Breadcrumb";
import DataTable from "react-data-table-component"
import customStyles from "../../../assets/css/customTableStyle"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"
import { BsTrashFill } from 'react-icons/bs';
import { Label, Input, Modal, ModalHeader, ModalBody, Form, FormFeedback, Row, Col } from "reactstrap"
import { post, get, del } from "../../../helpers/api_helper"
import toast from 'react-hot-toast';
import { amountFormat } from 'assets/js/numberFormatter';
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from 'react-redux';

const SharedUsers = () => {

    const [data, setData] = useState([])
    const buttonTypes = [{ value: 'Users', key: 'userData' }, { value: 'Create User', key: 'createUser' }]
    const [filterType, setFilterType] = useState("userData");
    const [postData, setPostData] = useState({ firstName: "", lastName: "", email: "", password: "", parent_id: JSON.parse(localStorage.getItem("authUser")).id })
    const [balance, setBalance] = useState("-");
    const [subUserBalance, setSubUserBalance] = useState("-");

    const [id, setId] = useState("")
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch();




    const headers = { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}`, };

    const columns = [
        {
            name: "User Name",
            selector: row => ` ${row.first_name} ${" "}  ${row.last_name}`,
        },
        {
            name: "Email Address",
            selector: row => ` ${row.email}`,
        },
        {
            name: "Balance",
            selector: row => ` ${row.balance}`,
        },
        {
            name: "Status",
            // selector: "created_at",
            selector: row => ` ${row.status == 1 ? "Active" : "Deactive"}`,
        },
        {
            name: "Actions",
            selector: (row) => row.status === 1 ?

                <>
                    <button onClick={() => handleDeleteUser(row?.id)} className={"btn btn-warning btn-sm waves-effect waves-light"} style={{ marginLeft: "10px", backgroundColor: "#FF0000" }}>Delete User</button>
                    {/* <BsTrashFill onClick={() => handleDeleteUser(row?.id)} size={20} color="#FF0000" cursor={"pointer"} /> */}
                    <button className={"btn btn-warning btn-sm waves-effect waves-light"} onClick={() => handleBalance(row.id)} style={{ marginLeft: "10px" }}>Add Balance</button>
                </>


                : "-"


        }
    ]


    const handleInputChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value })
    }

    const handleCreateUser = async (e) => {
        e.preventDefault()
        try {
            let loader = toast.loading("Creating User")
            let response = await post('/user/create-user', postData, { headers })
            console.log(response)
            if (response) {
                toast.dismiss(loader)
                toast.success("User Created")
                setFilterType("userData")
            }
        }
        catch (error) {
            toast.dismiss(loader)
            console.log(error)
            toast.error("Something Went Wrong")
        }
    }

    const handleDeleteUser = async (id) => {
        try {
            let confrm = confirm("Are you sure you want to suspend this user?");
            if (confrm) {
                const body = { id: id }
                await post('/user/suspend', body, { headers }).then(async (response) => {
                    if (response.status === 200) {
                        await fetchUsers();
                        toast.success(response.message);
                    }
                    else {
                        toast.error(response.message);
                    }
                });
            }
        }
        catch (error) {
            console.log(error)
            toast.error("Something Went Wrong")
        }
    }

    const fetchUsers = async (req, res) => {
        try {
            let response = await get(`/user/fetch-sub-user/${JSON.parse(localStorage.getItem("authUser")).id}`, { headers })
            setData(response.subUsers)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getUserBalance = async () => {
        setBalance("-")
        const body = { id: JSON.parse(localStorage.getItem("authUser")).id, }
        await post("/user/get-balance", body, { headers }).then(response => {
            if (response.status === 200) {
                localStorage.setItem("balance", response.balance)
                console.log(response, 'response')
                setBalance(response.balance)
                // setIsCreditEnable(response.is_credit_enable)
                // setMaxCreditAmount(response.max_credit_amount)
            }
        })
    }

    const handleBalance = async (id) => {
        // const id = e.target.getAttribute("data-id");
        await get('/admin/get-sub-user-by-id/' + id, { headers }).then(async (response) => {
            console.log("res", response);
            if (response.status === 200) {
                setId(id);
                validation.values.balance = "";
                setEmail(response.data.email);
                setSubUserBalance(response.data.balance);
                setName(response.data.first_name + " " + response.data.last_name);
                setModal(true);
                // toast.success(response.message);
            }
            else {
                toast.error(response.message);
            }
        });

    }

    const handleAddBalance = async (values) => {
        const { id, balance } = values;
        const body = { id: id, balance: balance, payment_mode: 'Admin',type: 'credit',description:`Credit by Admin:#${JSON.parse(localStorage.getItem("authUser")).id}`};
        await post('/admin/user/add-balance', body, { headers }).then(async (response) => {
            if (response.status === 200) { 
                await handleDeductBalance(balance,id)
                await fetchUsers(); 
                toast.success(response.message); 
                setModal(false); 
            }
            else {
                toast.error(response.message);
            }
        });
    }

    const handleDeductBalance = async (balance,userId) => {
        const body = { id:JSON.parse(localStorage.getItem("authUser")).id, balance: balance, payment_mode: 'Sent To Sub User', type: 'debit',description:`Amount sent to sub user:# ${userId}` };
        await post('/admin/user/add-balance', body, { headers }).then(async (response) => {
            console.log(response,'response of deduct balance')
            if (response.status === 200) {
                await fetchUsers();
                await getUserBalance()
            }
            else {

                toast.error(response.message);
            }
        });
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { id: id, balance: 0 },
        validationSchema: Yup.object({balance: Yup.number().required("Please Enter Balance").min(0, "Balance cannot be less than 0").max(Number(balance), `Balance cannot exceed ${balance}`)}),
        onSubmit: (values) => {
            dispatch(handleAddBalance(values));
        }
    });
    




    useEffect(() => {
        fetchUsers();
        getUserBalance();
    }, [])



    return (
        <div className='page-content'>


            <Breadcrumbs title="NullShip" breadcrumbItem="Users" />

            {/* Tabs Section */}

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ width: "fit-content", background: localStorage.getItem("theme") == "dark" ? "#222224" : "#E1E5E8" }} className="rounded-top">
                    {buttonTypes.map((button, index) => (
                        <button key={button.key} onClick={() => setFilterType(button.key)} className={"btn btn-md rounded-top " + (filterType === "userData" ? " text-dark" : " text-secondary")} style={{ marginLeft: "0px", marginRight: "0px", borderRadius: "4px 4px 0px 0px", borderColor: "rgba(0,0,0,0)", backgroundColor: filterType == button?.key ? (localStorage.getItem("theme") == "dark" ? "#2a3042" : "white") : (localStorage.getItem("theme") == "dark" ? "#222224" : "#E1E5E8") }}>{button.value}</button>
                    ))}
                </div>

                <span style={{ fontSize: "1.2rem" }}>
                    Balance: $ {balance != "-" ? amountFormat(parseFloat(balance).toFixed(2)) : "..."}
                </span>

            </div>

            {
                filterType === "userData" ?
                    <div>
                        <DataTable data={data} columns={columns} pagination={50} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>} paginationPerPage={50} paginationRowsPerPageOptions={[50, 100, 200, 500]} highlightOnHover={true} className={"order-table"} theme={localStorage.getItem("theme") == "dark" ? "solarized" : null} customStyles={localStorage.getItem("theme") == "dark" ? customDarkStyles : customStyles} />
                    </div> :

                    <div>
                        <Modal isOpen={true} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex="-1" onClosed={() => { }}>
                            <div>
                                <ModalHeader toggle={() => { setFilterType("userData"); }}>Create a new user</ModalHeader>
                                <ModalBody>
                                    <p className={"text-danger"}><b>Note:</b> Please ensure that you enter the correct information.</p>
                                    <form onSubmit={handleCreateUser} method='POST'>
                                        <div className={"col-md-12"}>
                                            <div className={"mb-3"}>
                                                <div className="form-group">
                                                    <Label className="form-label"> First Name <span style={{ color: "red" }}>*</span> </Label>
                                                    <Input name="firstName" className="form-control" placeholder="First Name" type="text" onChange={e => handleInputChange(e)} value={postData.firstName} required={true} />
                                                </div>
                                            </div>
                                            <div className={"mb-3"}>
                                                <div className="form-group">
                                                    <Label className="form-label"> Last Name<span style={{ color: "red" }}>*</span></Label>
                                                    <Input name="lastName" className="form-control" placeholder="Last Name" type="text" onChange={e => handleInputChange(e)} value={postData.lastName} required={true} />
                                                </div>
                                            </div>

                                            <div className={"mb-3"}>
                                                <div className="form-group">
                                                    <Label className="form-label">Email<span style={{ color: "red" }}>*</span></Label>
                                                    <Input name="email" className="form-control" placeholder="Email" type="text" onChange={e => handleInputChange(e)} value={postData.email} required={true} />
                                                </div>
                                            </div>

                                            <div className={"mb-3"}>
                                                <div className="form-group">
                                                    <Label className="form-label">Password<span style={{ color: "red" }}>*</span></Label>
                                                    <Input name="password" className="form-control" placeholder="Password" type="text" onChange={e => handleInputChange(e)} value={postData.password} required={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <button type={"submit"} className={"btn btn-success btn-sm"} style={{ float: "right" }}>Create User</button>
                                        <br />
                                        <br />
                                    </form>
                                </ModalBody>
                            </div>
                        </Modal>
                    </div>
            }


            <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex="-1" toggle={() => { setModal(!modal); }} onClosed={() => { setId(null); }}>
                <div>
                    <ModalHeader toggle={() => { setModal(!modal); }}> Add Balance </ModalHeader>
                    <ModalBody>
                        <p className="mb-2"> User id: <span className="text-primary">#{id}</span></p>
                        <p className="mb-2"> User Name: <span className="text-primary">{name}</span></p>
                        <p className="mb-2"> User Email: <span className="text-primary">{email}</span></p>
                        <p className="mb-2"> Current Balance: <span className="text-primary">${amountFormat(parseFloat(subUserBalance).toFixed(2))}</span></p>
                        <hr />
                        <Form className="form-horizontal" onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
                            <div className="mb-3">
                                <Label className="form-label">Balance</Label>
                                <Input name="balance" className="form-control" placeholder="Enter Balance" type="number" step="any" min={0} max={Number(balance)} onWheel={(e) => e.target.blur()} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.balance || ""} invalid={validation.touched.balance && validation.errors.balance ? true : false} />
                                {validation.touched.balance && validation.errors.balance ? (
                                    <FormFeedback type="invalid">{validation.errors.balance}</FormFeedback>
                                ) : null}
                            </div>
                            <Row className="mb-3">
                                <Col className="text-end">
                                    <button className="btn btn-primary w-md " type="submit">Add Balance</button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>



        </div>
    )
}

export default SharedUsers