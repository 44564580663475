// src/components/filter.
import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { post, get } from "../../../helpers/api_helper"
import { toast } from "react-hot-toast";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Label,
  Input,
  Modal, ModalHeader, ModalBody
} from "reactstrap"
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   Alert,
//   CardBody,
//   Button,
//   Label,
//   Input,
//   FormFeedback,
//   Form, CardTitle, DropdownMenu, DropdownItem
// } from "reactstrap"

import { Country, State } from "country-state-city"
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete"

// import Select from "react-select/base"
import states from "../../../assets/js/states"
import Select from "react-select"

function ApiSettings(props) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const [apiKeys, setApiKey] = useState([]);
  const [easyPost, setEasyPost] = useState("");
  const [shipEngine, setShipEngine] = useState("");
  const [aftershipLabel, setAftershipLabel] = useState("");
  const [shipster, setShipster] = useState("");
  const [shippo, setShippo] = useState("");
  const [labelaxxess, setLabelAxxess] = useState("");
  const [mailChimp, setMailChimp] = useState("");
  const [coinbase, setCoinbase] = useState("");
  const [coinbase_webhook_secret, setCoinbaseWebhookSecret] = useState("");
  const [aftership, setAftership] = useState("");
  const [aftership_webhook_secret, setAftershipWebhookSecret] = useState("");
  const [helcim, setHelcim] = useState("");
  const [payPal, setPayPal] = useState("");
  const [google, setGoogle] = useState("");
  const [activeUspKey, setActiveUspKey] = useState(null);
  const [activeUspV1Key, setActiveUspV1Key] = useState(null);
  const [activeUrl,setActiveUrl] = useState(null)
  const [activeUspsKey, setActiveUspsKey] = useState(null);

  const [googleValidationEnable, setGoogleValidationEnable] = useState("1");

  const [store_id, setStoreId] = useState(null);

  const [modalUpdateDetails, setModalUpdateDetails] = useState(false);
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [store_type, setStoreStype] = useState("");
  const [isChecked, setIsChecked] = useState(null);
  const [isTowFactorAuthChecked, setIsTowFactorAuthChecked] = useState(null);


  const [users, setUsers] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [user_id, setUserId] = useState([]);

  const [viewModal, setViewModal] = useState(false);
  const [assignedUserEmail, setAssignedUserEmail] = useState(null);
  const [assignedUserID, setAssignedUserID] = useState(null);



  //  name = ?, phone = ?, country = ?, address_street_1 = ?, address_street_2 = ?, city = ?, state = ?, zip = ?
  const handleShowAddressModal = async () => {
    get('/admin/get-address-settings', { headers }).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        // console.log("data",data);
        setStreet1(data?.address_street_1);
        setStreet2(data?.address_street_2);
        setCity(data?.city);
        setState(data?.state);
        setZip(data?.zip);
        setCountry(data?.country);
        setPhone(data?.phone);
        setName(data?.name);
        setModalUpdateDetails(true)
      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handleUpdateStoreDetails = async (id) => {
    // try {
    //   post('admin/store-address-settings/', {headers}).then((response) => {
    //     console.log(response)
    //   })
    // } catch (error) {
    //   console.log("error")
    // }
    // if(id == 0) {
    //   setStoreId(0);
    //   setName(filteredData[0].user_name);
    //   setPhone(filteredData[0].phone);
    //   setStreet1(filteredData[0].address1);
    //   setStreet2(filteredData[0].address2);
    //   setCity(filteredData[0].city);
    //   setState(filteredData[0].state);
    //   setZip(filteredData[0].zip);
    //   setCountry(filteredData[0].country);
    //   setStoreStype("manual");
    //   setModalUpdateDetails(true);
    // }
    // else {
    //   post('admin/store-address-settings/', {headers}).then((response) => {
    //     console.log("reee",response.data.name);
    //     setStoreId(id);
    //     setName(response.data.name);
    //     setStreet1(response.data.address1);
    //     setStreet2(response.data.address2);
    //     setCity(response.data.city);
    //     setState(response.data.state);
    //     setZip(response.data.zip);
    //     setCountry(response.data.country);
    //     setPhone(response.data.phone);
    //     setStoreStype(response.data.store_type);
    //     setModalUpdateDetails(true);
    //   });
    // }
  }

  const handleStoreDetailsSubmit = async (e) => {
    e.preventDefault();
    if (phone.toString().replace(/[^0-9]/g, '').replaceAll(" ", "").length < 10) {
      toast.error("Phone number must contain 10 digit");
      return false;
    }
    else {
      const body = {
        name: name,
        address_line_1: street1,
        address_line_2: street2,
        country: country,
        state: state,
        zip_code: zip,
        city: city,
        phone: phone
      }

      post('admin/store-address-settings', body, { headers }).then(async (response) => {
        if (response.status == 200) {
          toast.success(response.message);
          // console.log(response)
          const data = response.data
          //  name, phone, country, address_line_1, address_line_2, city, state, zip_code
          setStreet1("");
          setStreet2("");
          setCity("");
          setState("");
          setZip("");
          setCountry("");
          setPhone("");
          setName("");
        }
        else {
          setStreet1("");
          setStreet2("");
          setCity("");
          setState("");
          setZip("");
          setCountry("");
          setPhone("");
          setName("");
          toast.error(response.message);
        }

        setModalUpdateDetails(false);
      })

    }
  }

  const handleCountry = async (e) => {
    const { value } = e.target;
    setCountry(value);
  }

  const handleAddressStreet1 = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      // console.log("results", results);
      const addressComponents = results[0].address_components;
      // console.log("addressComponents", addressComponents);
      let street1 = "";
      let street2 = "";
      let country = "";
      let city = "";
      let state = "";
      let zip = "";
      addressComponents.map((el) => {
        // console.log("type", el.types[0], "long name", el.long_name);
        if (el.types[0] === "street_number") {
          street1 += el.long_name + " ";
        }
        else if (el.types[0] === "route") {
          street1 += el.long_name + " ";
        }
        else if (el.types[0] === "premise") {
          street1 += el.long_name + " ";
        }
        else if (el.types[0] === "subpremise") {
          street2 += el.long_name + " ";
        }
        else if (el.types[0] === "locality") {
          city += el.long_name;
        }
        else if (el.types[0] === "administrative_area_level_1") {
          state += el.short_name;
        }
        else if (el.types[0] === "country") {
          country += el.short_name;
        }
        else if (el.types[0] === "postal_code") {
          zip += el.long_name;
        }
      })
      if (street1 !== "") {
        street1 = street1.substring(0, (street1.length - 1));
      }

      if (street2 !== "") {
        street2 = street2.substring(0, (street2.length - 1));
      }
      setStreet1(street1);
      setStreet2(street2);
      setCity(city);
      setCountry(country);
      setState(state);
      setZip(zip);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const retrieveKeys = async () => {
    try {
      let arr = [];
      await get('/admin/get-api-keys', { headers }).then((response) => {
        // console.log("retrieveKeys", response.data);
        arr = response.data;
      });
      return arr;
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveApiSettings = async () => {
    try {
      let arr = [];
      await get('/admin/get-api-setting', { headers }).then((response) => {
        // console.log("retrieveKeys", response.data);
        setGoogleValidationEnable(response.data.value);
      });
      return arr;
    }
    catch (e) {
      // console.log(e)
    }
  }

  const retrieveAddressDetails = () => {
    get('admin/get-address-settings', { headers }).then(async (response) => {
      if (response.status == 200 && response.data) {
        console.log("response After", response)

        setStreet1(response.data.address_street_1);
        setStreet2(response.data.address_street_2);
        setCity(response.data.city);
        setState(response.data.state);
        setZip(response.data.zip);
        setCountry(response.data.country);
        setPhone(response.data.phone);
        setName(response.data.name);
        setAssignedUserID(response.data.user_id)
        setIsChecked(response.data.status === 1)
        // setModalUpdateDetails(true);
      }
    })
  }

  const retrieveUsers = async () => {
    try {
      get('/admin/getActiveUsers', { headers }).then((response) => {
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            const nameA = a.email.toLowerCase();
            const nameB = b.email.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }

        let users = [];
        for (let i = 0; i < response.data.length; i++) {
          users.push({
            label: response.data[i].email,
            value: response.data[i].id
          })
        }

        setUsers(users);

        // console.log("response.data",response.data);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }
  const retrieveTwoFactorAuth = () => {
    get('admin/get-users-two-factor-auth', { headers }).then(async (response) => {
      if (response.status == 200) {
        setIsTowFactorAuthChecked(response.data)
      }
    })
  }

  useEffect(() => {
    retrieveApiSettings()
    retrieveAddressDetails()
    retrieveTwoFactorAuth();
    retrieveUsers();
    retrieveKeys().then((res) => {
      setEasyPost(res[res.findIndex(el => el.name === "easypost")].api_key);
      setShipEngine(res[res.findIndex(el => el.name === "shipengine")].api_key)
      setAftershipLabel(res[res.findIndex(el => el.name === "aftership-label")].api_key)
      setShipster(res[res.findIndex(el => el.name === "shipster")].api_key);
      setShippo(res[res.findIndex(el => el.name === "shippo")].api_key);
      setLabelAxxess(res[res.findIndex(el => el.name === "labelaxxess")].api_key);
      setMailChimp(res[res.findIndex(el => el.name === "mailchimp")].api_key);
      setPayPal(res[res.findIndex(el => el.name === "paypal")].api_key);

      setGoogle(res[res.findIndex(el => el.name === "google")].api_key);

      setCoinbase(res[res.findIndex(el => el.name === "coinbase")].api_key);
      setCoinbaseWebhookSecret(res[res.findIndex(el => el.name === "coinbase_webhook_secret")].api_key);

      setAftership(res[res.findIndex(el => el.name === "aftership")].api_key);
      setAftershipWebhookSecret(res[res.findIndex(el => el.name === "aftership_webhook_secret")].api_key);

      setHelcim(res[res.findIndex(el => el.name === "helcim")].api_key);

      if (res[res.findIndex(el => el.name === "labelaxxess")].status == 1) {
        setActiveUspsKey("labelaxxess");
      }
      else if (res[res.findIndex(el => el.name === "shippo")].status == 1) {
        setActiveUspsKey("shippo");
      }

      if (res[res.findIndex(el => el.name === "easypost")].status == 1) {
        setActiveUspKey("easypost");
      }
      else if (res[res.findIndex(el => el.name === "shipengine")].status == 1) {
        setActiveUspKey("shipengine");
      }
      else if (res[res.findIndex(el => el.name === "aftership-label")].status == 1) {
        setActiveUspKey("aftership-label");
      }
      else if (res[res.findIndex(el => el.name === "shippo-ip")].status == 1) {
        setActiveUspKey("shippo-ip");
      }

      if (res[res.findIndex(el => el.name === "easypost-v1")].status == 1) {
        setActiveUspV1Key("easypost-v1");
      }
      else if (res[res.findIndex(el => el.name === "shipengine-v1")].status == 1) {
        setActiveUspV1Key("shipengine-v1");
      }
      else if (res[res.findIndex(el => el.name === "aftership-label-v1")].status == 1) {
        setActiveUspV1Key("aftership-label-v1");
      }
      else if (res[res.findIndex(el => el.name === "shippo-ip-v1")].status == 1) {
        setActiveUspV1Key("shippo-ip-v1");
      }
    });
  }, []);


  const handleApiKeys = async (type, key) => {
    if (key !== "") {
      let body = {
        name: type,
        api_key: key
      }
      // console.log("body",body);
      post('/admin/update-api-key', body, { headers }).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          retrieveKeys().then((res) => {
            setEasyPost(res[res.findIndex(el => el.name === "easypost")].api_key);
            setShipEngine(res[res.findIndex(el => el.name === "shipengine")].api_key)
            setAftershipLabel(res[res.findIndex(el => el.name === "aftership-label")].api_key)
            setShipster(res[res.findIndex(el => el.name === "shipster")].api_key);
            setShippo(res[res.findIndex(el => el.name === "shippo")].api_key);
            setLabelAxxess(res[res.findIndex(el => el.name === "labelaxxess")].api_key);
            setMailChimp(res[res.findIndex(el => el.name === "mailchimp")].api_key);
            setPayPal(res[res.findIndex(el => el.name === "paypal")].api_key);

            setGoogle(res[res.findIndex(el => el.name === "google")].api_key);

            setCoinbase(res[res.findIndex(el => el.name === "coinbase")].api_key);
            setCoinbaseWebhookSecret(res[res.findIndex(el => el.name === "coinbase_webhook_secret")].api_key);

            setAftership(res[res.findIndex(el => el.name === "aftership")].api_key);
            setAftershipWebhookSecret(res[res.findIndex(el => el.name === "aftership_webhook_secret")].api_key);

            setHelcim(res[res.findIndex(el => el.name === "helcim")].api_key);

            if (res[res.findIndex(el => el.name === "labelaxxess")].status == 1) {
              setActiveUspsKey("labelaxxess");
            }
            else if (res[res.findIndex(el => el.name === "shippo")].status == 1) {
              setActiveUspsKey("shippo");
            }

            if (res[res.findIndex(el => el.name === "easypost")].status == 1) {
              setActiveUspKey("easypost");
            }
            else if (res[res.findIndex(el => el.name === "shipengine")].status == 1) {
              setActiveUspKey("shipengine");
            }
            else if (res[res.findIndex(el => el.name === "aftership-label")].status == 1) {
              setActiveUspKey("aftership-label");
            }

            if (res[res.findIndex(el => el.name === "easypost-v1")].status == 1) {
              setActiveUspV1Key("easypost-v1");
            }
            else if (res[res.findIndex(el => el.name === "shipengine-v1")].status == 1) {
              setActiveUspV1Key("shipengine-v1");
            }
            else if (res[res.findIndex(el => el.name === "aftership-label-v1")].status == 1) {
              setActiveUspV1Key("aftership-label-v1");
            }
          });
        }
        else {
          toast.error(response.message);
        }
      })
    }
    else {
      toast.error(type.toString().toLocaleUpperCase() + " is required");
    }
  }

  const handleUspsActiveKey = (e) => {
    // console.log("ee",e.target.value);
    let body = {
      name: e.target.value
    };
    // console.log("body",body);
    post('/admin/update-api-key-status', body, { headers }).then((response) => {
      if (response.status === 200) {
        toast.success(response.message);
        retrieveKeys().then((res) => {
          if (res[res.findIndex(el => el.name === "labelaxxess")].status == 1) {
            setActiveUspsKey("labelaxxess");
          }
          else if (res[res.findIndex(el => el.name === "shippo")].status == 1) {
            setActiveUspsKey("shippo");
          }
        })
      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handleApiSetting = async (e) => {
    setGoogleValidationEnable(e.target.value)
    try {
      const body = {
        google_validation_enable: e.target.value
      }
      post('/admin/update-api-setting', body, { headers }).then((res) => {
        if (res.status == 200) {
          toast.success(res.message);
        }
        else {
          toast.error(res.message);
        }
      })
    }
    catch (e) {

    }
  }

  const handleCheckboxChange = (event) => {
    // console.log('inside...')
    const check = event.target.checked;
    setIsChecked(!check);
    // Call your function with the isChecked value
    const body = { status: !check };
    post('admin/update-address-status', body, { headers }).then(async (response) => {
      if (response.status === 200) {
        toast.success(response.message);
      }
      else {
        toast.error(response.message);
      }
    })
  };

  const handleTwoFactorAuth = (event) => {
    const check = event.target.checked;
    const body = { two_factor_auth: (!check ? 1 : 0) };
    setIsTowFactorAuthChecked(!check)
    console.log("body", body)
    post('/admin/update-users-two-factor-auth', body, { headers }).then(async (response) => {
      if (response.status === 200) {
        toast.success(response.message);
      }
      else {
        toast.error(response.message);
      }
    })
  }

  const handleActivateUpsApi = (e) => {
    try {
      const payload = {
        name: e.target.value
      }
      post('/admin/update-ups-api-key', payload, { headers }).then((response) => {
        if (response.status == 200) {
          toast.success(response.message);
          retrieveKeys().then((res) => {
            if (res[res.findIndex(el => el.name === "easypost")].status == 1) {
              setActiveUspKey("easypost");
            }
            else if (res[res.findIndex(el => el.name === "shipengine")].status == 1) {
              setActiveUspKey("shipengine");
            }
            else if (res[res.findIndex(el => el.name === "aftership-label")].status == 1) {
              setActiveUspKey("aftership-label");
            }
            else if (res[res.findIndex(el => el.name === "shippo-ip")].status == 1) {
              setActiveUspKey("shippo-ip");
            }
          })
        }
        else {
          toast.error(response.message);
        }
      });
    }
    catch (e) {
      console.log("erorr", e);
    }
  }

  const handleActivateUpsv1Api = (e) => {
    console.log("..", e.target.value);
    try {
      const payload = {
        name: e.target.value
      }
      post('/admin/update-ups-v1-api-key', payload, { headers }).then((response) => {
        if (response.status == 200) {
          toast.success(response.message);
          retrieveKeys().then((res) => {
            if (res[res.findIndex(el => el.name === "easypost-v1")].status == 1) {
              setActiveUspV1Key("easypost-v1");
            }
            else if (res[res.findIndex(el => el.name === "shipengine-v1")].status == 1) {
              setActiveUspV1Key("shipengine-v1");
            }
            else if (res[res.findIndex(el => el.name === "aftership-label-v1")].status == 1) {
              setActiveUspV1Key("aftership-label-v1");
            }
            else if (res[res.findIndex(el => el.name === "shippo-ip-v1")].status == 1) {
              setActiveUspV1Key("shippo-ip-v1");
            }
          })
        }
        else {
          toast.error(response.message);
        }
      });
    }
    catch (e) {
      console.log("erorr", e);
    }
  }

  const handleActivateUpsv1ApiUrl = (e) => {
    console.log("..", e.target.value);
    try {
      const payload = {
        name: e.target.value
      }
      post('/admin/update-ups-v1-api-url', payload, { headers }).then((response) => {
        if (response.status == 200) {
          toast.success(response.message);
          retrieveKeys().then((res) => {
            if (res[res.findIndex(el => el.name === "old-usps-url")].status == 1) {
              setActiveUrl("https://67.227.248.196/generate.php");
            }
            else if (res[res.findIndex(el => el.name === "new-usps-url")].status == 1) {
              setActiveUrl("https://50.28.110.103/generate.php");
            }
          })
        }
        else {
          toast.error(response.message);
        }
      });
    }
    catch (e) {
      console.log("erorr", e);
    }
  }

  const toggleAddModal = () => {

    setAddModal(!addModal)

  }

  const handleAddModal = () => {

    toggleAddModal()
  }

  const handleAddUser = async (e) => {
    e.preventDefault()
    const body = { user_id: user_id }
    post('admin/update-address-user', body, { headers }).then(async (response) => {
      if (response.status == 200) {
        toast.success(response.message);
        retrieveAddressDetails()
        setAddModal(false)

      }
      else {

        toast.error(response.message);
        setAddModal(false)
        retrieveAddressDetails();


      }
    })


  }

  const toggleViewModal = () => {
    console.log("Toggling modal");
    setViewModal(!viewModal);
  };

  const handleViewAssignUser = (user_id) => {
    console.log("User ID:", user_id);
    setAssignedUserEmail(JSON.parse(user_id));

    setViewModal(true);
  };

  const handleDeleteUser = (user_id) => {
    let confrm = confirm("Are you sure you want to enable this user?");
    if (confrm) {
      const body = {
        user_id: user_id,


      };
      console.log("delete", body)
      post('/admin/delete-address-user', body, { headers }).then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          // setAssignedUserEmail(null)
          //toggleViewModal()
          setViewModal(false);
          retrieveAddressDetails();

        } else {
          toast.success(response.message);
        }
      })

    }
  }
  //meta title
  document.title = "Api Settings | NullShip";


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" breadcrumbItem="Api Settings" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={8} lg={7} xl={7}>
            <Card className="overflow-hidden">
              <CardBody>
                <div className="mb-0">
                  <label className="mb-2">Two Factor Authentication</label>
                  <div className="hstack gap-3 mb-0 pb-1">
                    {/* <div class="check-box"> */}
                    <input
                      className="switch"
                      type="checkbox"
                      onClick={handleTwoFactorAuth}
                      checked={isTowFactorAuthChecked}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="overflow-hidden">
              <CardBody>
                <fieldset className="mb-0">
                  <legend>Default Address Settings</legend>
                  <div className="mb-2 mt-2">
                    {/* <div className="hstack gap-3 mb-0 pb-1"> */}
                    <div className="mb-0 d-flex justify-content-between align-items-center">

                      {/* <div> */}
                      <input
                        className="switch"
                        type="checkbox"
                        onClick={handleCheckboxChange}
                        checked={isChecked}

                      />
                      {/* </div> */}
                      <div>
                        <button className={"btn btn-danger waves-effect waves-light btn-sm"} style={{ marginRight: "4px" }} onClick={() => handleViewAssignUser(assignedUserID)}>
                          Exempted Users
                        </button>
                        <button className={"btn btn-light waves-effect waves-light btn-sm"} onClick={() => handleAddModal()} >Exempt Users</button>
                        <button type="button" onClick={() => handleShowAddressModal()} className="btn btn-light btn-sm waves-effect waves-light" style={{ marginLeft: "4px" }}>Default Address</button>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </CardBody>
            </Card>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">Api Keys</CardTitle>
                <fieldset style={{ marginTop: "30px" }}>
                  <legend>UPS & FedEx API (PORTAL)</legend>
                  <p className={"text-danger"}><i className={"bx bx-info-circle"}></i> Set API Key while creating <a style={{ color: "#f46a6a", fontWeight: "600", textDecoration: "underline !important" }} href={"/carrier-ids"}>Carrier Id</a></p>
                  <div className="mb-3">
                    {/*<Label className="form-label">EasyPost</Label>*/}
                    <div className={"hstack gap-3 mb-3"}>
                      <input type="radio" name="active-api-for-ups" value="easypost" checked={activeUspKey == "easypost" ? true : false} onClick={handleActivateUpsApi} />
                      <label className={"mt-2"}>EasPost</label>
                      {/*<input*/}
                      {/*  className="form-control me-auto"*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Enter EasyPost Key..."*/}
                      {/*  aria-label="Enter EasyPost Key..."*/}
                      {/*  value={easyPost}*/}
                      {/*  onInput={(e) => { setEasyPost(e.target.value) }}*/}
                      {/*/>*/}
                      {/*<button type="button" onClick={() => handleApiKeys('easypost', easyPost)} className="btn btn-success">Save</button>*/}
                      {/*<div className="vr"></div>*/}
                      {/*<button type="button" onClick={() => { navigator.clipboard.writeText(easyPost); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>*/}
                    </div>
                  </div>
                  <div className="mb-3">
                    {/*<Label className="form-label">ShipEngine</Label>*/}
                    <div className={"hstack gap-3 mb-3"}>
                      <input type="radio" name="active-api-for-ups" value="shipengine" checked={activeUspKey == "shipengine" ? true : false} onClick={handleActivateUpsApi} />
                      <label className={"mt-2"}>ShipEngine</label>
                      {/*<input*/}
                      {/*  className="form-control me-auto"*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Enter ShipEngine Key..."*/}
                      {/*  aria-label="Enter ShipEngine Key..."*/}
                      {/*  value={shipEngine}*/}
                      {/*  onInput={(e) => { setShipEngine(e.target.value) }}*/}
                      {/*/>*/}
                      {/*<button type="button" onClick={() => handleApiKeys('shipengine', shipEngine)} className="btn btn-success">Save</button>*/}
                      {/*<div className="vr"></div>*/}
                      {/*<button type="button" onClick={() => { navigator.clipboard.writeText(shipEngine); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>*/}
                    </div>
                  </div>
                  <div className="mb-3">
                    {/*<Label className="form-label">AfterShip (For Labels)</Label>*/}
                    <div className={"hstack gap-3 mb-3"}>
                      <input type="radio" name="active-api-for-ups" value="aftership-label" checked={activeUspKey == "aftership-label" ? true : false} onClick={handleActivateUpsApi} />
                      <label className={"mt-2"}>AfterShip (For Labels)</label>
                      {/*<input*/}
                      {/*  className="form-control me-auto"*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Enter AfterShip (For Labels) Key..."*/}
                      {/*  aria-label="Enter AfterShip (For Labels) Key..."*/}
                      {/*  value={aftershipLabel}*/}
                      {/*  onInput={(e) => { setAftershipLabel(e.target.value) }}*/}
                      {/*/>*/}
                      {/*<button type="button" onClick={() => handleApiKeys('aftership-label', aftershipLabel)} className="btn btn-success">Save</button>*/}
                      {/*<div className="vr"></div>*/}
                      {/*<button type="button" onClick={() => {navigator.clipboard.writeText(aftershipLabel);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>*/}
                    </div>
                  </div>
                  <div className="mb-3">
                    {/*<Label className="form-label">AfterShip (For Labels)</Label>*/}
                    <div className={"hstack gap-3 mb-3"}>
                      <input type="radio" name="active-api-for-ups" value="shippo-ip" checked={activeUspKey == "shippo-ip" ? true : false} onClick={handleActivateUpsApi} />
                      <label className={"mt-2"}>Shippo IP (For Labels)</label>
                      {/*<input*/}
                      {/*  className="form-control me-auto"*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Enter AfterShip (For Labels) Key..."*/}
                      {/*  aria-label="Enter AfterShip (For Labels) Key..."*/}
                      {/*  value={aftershipLabel}*/}
                      {/*  onInput={(e) => { setAftershipLabel(e.target.value) }}*/}
                      {/*/>*/}
                      {/*<button type="button" onClick={() => handleApiKeys('aftership-label', aftershipLabel)} className="btn btn-success">Save</button>*/}
                      {/*<div className="vr"></div>*/}
                      {/*<button type="button" onClick={() => {navigator.clipboard.writeText(aftershipLabel);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>*/}
                    </div>
                  </div>
                </fieldset>

                <fieldset style={{ marginTop: "30px" }}>
                  <legend>UPS & FedEx API (V1)</legend>
                  <p className={"text-danger"}><i className={"bx bx-info-circle"}></i> Set API Key while creating <a style={{ color: "#f46a6a", fontWeight: "600", textDecoration: "underline !important" }} href={"/carrier-ids"}>Carrier Id</a></p>
                  <div className="mb-3">
                    {/*<Label className="form-label">EasyPost</Label>*/}
                    <div className={"hstack gap-3 mb-3"}>
                      <input type="radio" name="active-v1-api-for-ups" value="easypost-v1" checked={activeUspV1Key == "easypost-v1" ? true : false} onClick={handleActivateUpsv1Api} />
                      <label className={"mt-2"}>EasPost</label>
                      {/*<input*/}
                      {/*  className="form-control me-auto"*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Enter EasyPost Key..."*/}
                      {/*  aria-label="Enter EasyPost Key..."*/}
                      {/*  value={easyPost}*/}
                      {/*  onInput={(e) => { setEasyPost(e.target.value) }}*/}
                      {/*/>*/}
                      {/*<button type="button" onClick={() => handleApiKeys('easypost', easyPost)} className="btn btn-success">Save</button>*/}
                      {/*<div className="vr"></div>*/}
                      {/*<button type="button" onClick={() => { navigator.clipboard.writeText(easyPost); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>*/}
                    </div>
                  </div>
                  <div className="mb-3">
                    {/*<Label className="form-label">ShipEngine</Label>*/}
                    <div className={"hstack gap-3 mb-3"}>
                      <input type="radio" name="active-v1-api-for-ups" value="shipengine-v1" checked={activeUspV1Key == "shipengine-v1" ? true : false} onClick={handleActivateUpsv1Api} />
                      <label className={"mt-2"}>ShipEngine</label>
                      {/*<input*/}
                      {/*  className="form-control me-auto"*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Enter ShipEngine Key..."*/}
                      {/*  aria-label="Enter ShipEngine Key..."*/}
                      {/*  value={shipEngine}*/}
                      {/*  onInput={(e) => { setShipEngine(e.target.value) }}*/}
                      {/*/>*/}
                      {/*<button type="button" onClick={() => handleApiKeys('shipengine', shipEngine)} className="btn btn-success">Save</button>*/}
                      {/*<div className="vr"></div>*/}
                      {/*<button type="button" onClick={() => { navigator.clipboard.writeText(shipEngine); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>*/}
                    </div>
                  </div>
                  <div className="mb-3">
                    {/*<Label className="form-label">AfterShip (For Labels)</Label>*/}
                    <div className={"hstack gap-3 mb-3"}>
                      <input type="radio" name="active-v1-api-for-ups" value="aftership-label-v1" checked={activeUspV1Key == "aftership-label-v1" ? true : false} onClick={handleActivateUpsv1Api} />
                      <label className={"mt-2"}>AfterShip (For Labels)</label>
                      {/*<input*/}
                      {/*  className="form-control me-auto"*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Enter AfterShip (For Labels) Key..."*/}
                      {/*  aria-label="Enter AfterShip (For Labels) Key..."*/}
                      {/*  value={aftershipLabel}*/}
                      {/*  onInput={(e) => { setAftershipLabel(e.target.value) }}*/}
                      {/*/>*/}
                      {/*<button type="button" onClick={() => handleApiKeys('aftership-label', aftershipLabel)} className="btn btn-success">Save</button>*/}
                      {/*<div className="vr"></div>*/}
                      {/*<button type="button" onClick={() => {navigator.clipboard.writeText(aftershipLabel);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>*/}
                    </div>
                  </div>
                  <div className="mb-3">
                    {/*<Label className="form-label">AfterShip (For Labels)</Label>*/}
                    <div className={"hstack gap-3 mb-3"}>
                      <input type="radio" name="active-v1-api-for-ups" value="shippo-ip-v1" checked={activeUspV1Key == "shippo-ip-v1" ? true : false} onClick={handleActivateUpsv1Api} />
                      <label className={"mt-2"}>Shippo IP (For Labels)</label>
                      {/*<input*/}
                      {/*  className="form-control me-auto"*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Enter AfterShip (For Labels) Key..."*/}
                      {/*  aria-label="Enter AfterShip (For Labels) Key..."*/}
                      {/*  value={aftershipLabel}*/}
                      {/*  onInput={(e) => { setAftershipLabel(e.target.value) }}*/}
                      {/*/>*/}
                      {/*<button type="button" onClick={() => handleApiKeys('aftership-label', aftershipLabel)} className="btn btn-success">Save</button>*/}
                      {/*<div className="vr"></div>*/}
                      {/*<button type="button" onClick={() => {navigator.clipboard.writeText(aftershipLabel);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>*/}
                    </div>
                  </div>
                </fieldset>
                {/*<div className={"mb-3"}>*/}
                {/*  <Label className="form-label">Shipster</Label>*/}
                {/*  <div className={"hstack gap-3 mb-3"}>*/}
                {/*    <input*/}
                {/*      className="form-control me-auto"*/}
                {/*      type="text"*/}
                {/*      placeholder="Enter Shipster Key..."*/}
                {/*      aria-label="Enter Shipster Key..."*/}
                {/*      value={shipster}*/}
                {/*      onInput={(e) => { setShipster(e.target.value) }}*/}
                {/*    />*/}
                {/*    <button type="button" onClick={() => handleApiKeys('shipster', shipster)} className="btn btn-success">Save</button>*/}
                {/*    <div className="vr"></div>*/}
                {/*    <button type="button" onClick={() => {navigator.clipboard.writeText(shipster);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <fieldset style={{ marginTop: "30px" }}>
                  <legend>USPS APIs</legend>
                  {/*<div className="mb-3">*/}
                  {/*  <Label className="form-label">Shippo</Label>*/}
                  {/*  <div className={"hstack gap-3 mb-3"}>*/}
                  {/*    <div>*/}
                  {/*      <input type={"radio"} onClick={handleUspsActiveKey} checked={activeUspsKey=="shippo"?true:false} className={"form-check-input"} value={"shippo"} name={"usps_api_key"}/>*/}
                  {/*    </div>*/}
                  {/*    <input*/}
                  {/*      className="form-control me-auto"*/}
                  {/*      type="text"*/}
                  {/*      placeholder="Enter Shippo Key..."*/}
                  {/*      aria-label="Enter Shippo Key..."*/}
                  {/*      value={shippo}*/}
                  {/*      onInput={(e) => { setShippo(e.target.value) }}*/}
                  {/*    />*/}
                  {/*    <button type="button" onClick={() => handleApiKeys('shippo', shippo)} className="btn btn-success">Save</button>*/}
                  {/*    <div className="vr"></div>*/}
                  {/*    <button type="button" onClick={() => {navigator.clipboard.writeText(shippo);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="mb-3">
                    <Label className="form-label">Label Axxess</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      {/*<div>*/}
                      {/*  <input type={"radio"} onClick={handleUspsActiveKey} checked={activeUspsKey=="labelaxxess"?true:false} className={"form-check-input"} value={"labelaxxess"} name={"usps_api_key"}/>*/}
                      {/*</div>*/}
                      <input
                        className="form-control me-auto"
                        type="text"
                        placeholder="Enter Label Axxess Key..."
                        aria-label="Enter Label Axxess Key..."
                        value={labelaxxess}
                        onInput={(e) => { setLabelAxxess(e.target.value) }}
                      />
                      <button type="button" onClick={() => handleApiKeys('labelaxxess', labelaxxess)} className="btn btn-success">Save</button>
                      <div className="vr"></div>
                      <button type="button" onClick={() => { navigator.clipboard.writeText(labelaxxess); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>
                    </div>
                  </div>
                </fieldset>
                {/*<div className="mb-3">*/}
                {/*  <Label className="form-label">MailChimp</Label>*/}
                {/*  <div className={"hstack gap-3 mb-3"}>*/}
                {/*    <input*/}
                {/*      className="form-control me-auto"*/}
                {/*      type="text"*/}
                {/*      placeholder="Enter MailChimp Key..."*/}
                {/*      aria-label="Enter MailChimp Key..."*/}
                {/*      value={mailChimp}*/}
                {/*      onInput={(e) => { setMailChimp(e.target.value) }}*/}
                {/*    />*/}
                {/*    <button type="button" onClick={() => handleApiKeys('mailchimp', mailChimp)} className="btn btn-success">Save</button>*/}
                {/*    <div className="vr"></div>*/}
                {/*    <button type="button" onClick={() => {navigator.clipboard.writeText(mailChimp);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <fieldset style={{ marginTop: "30px" }}>
                  <legend>Coinbase</legend>
                  <div className="mb-3">
                    <Label className="form-label">Api Key</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      <input
                        className="form-control me-auto"
                        type="text"
                        placeholder="Enter Coinbase Key..."
                        aria-label="Enter Coinbase Key..."
                        value={coinbase}
                        onInput={(e) => { setCoinbase(e.target.value) }}
                      />
                      <button type="button" onClick={() => handleApiKeys('coinbase', coinbase)} className="btn btn-success">Save</button>
                      <div className="vr"></div>
                      <button type="button" onClick={() => { navigator.clipboard.writeText(coinbase); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Webhook Secret</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      <input
                        className="form-control me-auto"
                        type="text"
                        placeholder="Enter Coinbase Webhook Secret..."
                        aria-label="Enter Coinbase Webhook Secret..."
                        value={coinbase_webhook_secret}
                        onInput={(e) => { setCoinbaseWebhookSecret(e.target.value) }}
                      />
                      <button type="button" onClick={() => handleApiKeys('coinbase_webhook_secret', coinbase_webhook_secret)} className="btn btn-success">Save</button>
                      <div className="vr"></div>
                      <button type="button" onClick={() => { navigator.clipboard.writeText(coinbase_webhook_secret); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>
                    </div>
                  </div>
                </fieldset>

                <fieldset style={{ marginTop: "30px" }}>
                  <legend>AfterShip</legend>
                  <div className="mb-3">
                    <Label className="form-label">Api Key</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      <input
                        className="form-control me-auto"
                        type="text"
                        placeholder="Enter After Ship Key..."
                        aria-label="Enter After Ship Key..."
                        value={aftership}
                        onInput={(e) => { setAftership(e.target.value) }}
                      />
                      <button type="button" onClick={() => handleApiKeys('aftership', aftership)} className="btn btn-success">Save</button>
                      <div className="vr"></div>
                      <button type="button" onClick={() => { navigator.clipboard.writeText(aftership); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Webhook Secret</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      <input
                        className="form-control me-auto"
                        type="text"
                        placeholder="Enter After Ship Webhook Secret..."
                        aria-label="Enter After Ship Webhook Secret..."
                        value={aftership_webhook_secret}
                        onInput={(e) => { setAftershipWebhookSecret(e.target.value) }}
                      />
                      <button type="button" onClick={() => handleApiKeys('aftership_webhook_secret', aftership_webhook_secret)} className="btn btn-success">Save</button>
                      <div className="vr"></div>
                      <button type="button" onClick={() => { navigator.clipboard.writeText(aftership_webhook_secret); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Payment API</legend>
                  <div className="mb-3">
                    <Label className="form-label">Helcim Payment API Key</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      <input
                        className="form-control me-auto"
                        type="text"
                        placeholder="Enter Helcim Payment API Key..."
                        aria-label="Enter Helcim Payment API Key..."
                        value={helcim}
                        onInput={(e) => { setHelcim(e.target.value) }}
                      />
                      <button type="button" onClick={() => handleApiKeys('helcim', helcim)} className="btn btn-success">Save</button>
                      <div className="vr"></div>
                      <button type="button" onClick={() => { navigator.clipboard.writeText(helcim); toast.success("Copied!") }} className="btn btn-outline-dark">Copy</button>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <legend>v1 API Settings</legend>
                  <div className="mb-3">
                    <Label className="form-label">Google Address Validation</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      <select className={"form-select"} defaultValue={googleValidationEnable} value={googleValidationEnable} onChange={handleApiSetting}>
                        <option value="1">Enable</option>
                        <option value="0">Disable</option>
                      </select>
                    </div>
                  </div>
                </fieldset>
                {/*<div className="mb-3">*/}
                {/*  <Label className="form-label">Google Api Key</Label>*/}
                {/*  <div className={"hstack gap-3 mb-3"}>*/}
                {/*    <input*/}
                {/*      className="form-control me-auto"*/}
                {/*      type="text"*/}
                {/*      placeholder="Enter Google Api Key..."*/}
                {/*      aria-label="Enter Google Api Key..."*/}
                {/*      value={google}*/}
                {/*      onInput={(e) => { setGoogle(e.target.value) }}*/}
                {/*    />*/}
                {/*    <button type="button" onClick={() => handleApiKeys('google', google)} className="btn btn-success">Save</button>*/}
                {/*    <div className="vr"></div>*/}
                {/*    <button type="button" onClick={() => {navigator.clipboard.writeText(google);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="mb-3">*/}
                {/*  <Label className="form-label">PayPal</Label>*/}
                {/*  <div className={"hstack gap-3 mb-3"}>*/}
                {/*    <input*/}
                {/*      className="form-control me-auto"*/}
                {/*      type="text"*/}
                {/*      placeholder="Enter PayPal Key..."*/}
                {/*      aria-label="Enter PayPal Key..."*/}
                {/*      value={payPal}*/}
                {/*      onInput={(e) => { setPayPal(e.target.value) }}*/}
                {/*    />*/}
                {/*    <button type="button" onClick={() => handleApiKeys('paypal', payPal)} className="btn btn-success">Save</button>*/}
                {/*    <div className="vr"></div>*/}
                {/*    <button type="button" onClick={() => {navigator.clipboard.writeText(payPal);toast.success("Copied!")}} className="btn btn-outline-dark">Copy</button>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </CardBody>
            </Card>

            <Card className="overflow-hidden">
            </Card>
          </Col>
        </Row>
      </div>
      {/*Modal*/}
      <Modal
        isOpen={modalUpdateDetails}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        toggle={() => {
          setModalUpdateDetails(!modalUpdateDetails);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModalUpdateDetails(!modalUpdateDetails);
            }}
          >
            Default Address Settings
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleStoreDetailsSubmit}>
              <div className={"row"}>
                <div className={"col-md-6 mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">Name <span style={{ color: "red", display: (store_type == "shopify" || store_type == "zenventory" || store_type == "sellercloud" ? "none" : "inline-flex") }}>*</span></Label>
                    <Input
                      name="name"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Name"
                      type="text"
                      onChange={(e) => {
                        if (store_type != "shopify" && store_type != "zenventory" && store_type != "sellercloud") {
                          setName(e.target.value)
                        }
                      }}
                      readOnly={store_type == "shopify" || store_type == "zenventory" || store_type == "sellercloud"}
                      disabled={store_type == "shopify" || store_type == "zenventory" || store_type == "sellercloud"}
                      value={name}
                      required={store_type == "shopify" || store_type == "zenventory" || store_type == "sellercloud" ? false : true}
                    />
                  </div>
                </div>
                <div className={"col-md-6 mb-3"}>
                  <div className="form-group">
                    <Label className="form-label">Phone <span style={{ color: "red" }}>*</span></Label>
                    <Input
                      name="phone"
                      // value={name}
                      className="form-control non-arrow-number"
                      placeholder="Enter Phone"
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      required={true}
                    />
                  </div>
                </div>
                <div className={"col-md-6"}>
                  <PlacesAutocomplete
                    value={street1}
                    onChange={setStreet1}
                    onSelect={handleAddressStreet1}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div style={{ position: "relative" }}>
                        <div className="form-group">
                          <Label className="form-label">Address Street 1 <span style={{ color: "red" }}>*</span></Label>
                          <Input
                            name="from_street1"
                            // value={name}
                            className="form-control"
                            {...getInputProps({ placeholder: "Enter Ship To Street 1" })}
                            type="text"
                            // onChange={(e) => setFromStreet1(e.target.value)}
                            required={true}
                          />
                        </div>
                        <div style={{ position: "absolute", top: "65px", left: "0px", backgroundColor: "white", zIndex: "99", width: "100%" }}>
                          {loading ? <div style={{ backgroundColor: localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "#f1f1f1" }}>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active ? localStorage.getItem("theme") == "dark" ? "#333" : "#ff6600" : localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "#f1f1f1",
                              padding: "5px 10px", border: "1px solid #efefef",
                              cursor: "pointer"
                            };
                            return (
                              <div
                                key={suggestion.id}
                                {...getSuggestionItemProps(suggestion, { style })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className={"col-md-6"}>
                  <div className="form-group">
                    <Label className="form-label">Address Street 2</Label>
                    <Input
                      name="address2"
                      // value={name}
                      className="form-control"
                      placeholder="Enter Address Street 2"
                      type="text"
                      onChange={(e) => setStreet2(e.target.value)}
                      value={street2}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-md-12 mt-3"}>
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">Country <span style={{ color: "red" }}>*</span></Label>
                      <select
                        name={"country"}
                        className="form-select"
                        onChange={handleCountry}
                        value={country}
                        required={true}
                      >
                        <option disabled={true} selected={country ? false : true} value={""}>Select a country</option>
                        {Country.getAllCountries().map((part, id) => {
                          return (<option key={id} value={part.isoCode}>{part.name}</option>);
                        })};
                      </select>
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">City <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        name="to_city"
                        // value={name}
                        className="form-control"
                        placeholder="Enter City"
                        type="text"
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">Zip <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        name="to_zip"
                        // value={name}
                        className="form-control non-arrow-number"
                        placeholder="Enter Zip"
                        type="text"
                        onChange={(e) => setZip(e.target.value)}
                        value={zip}
                        required={true}
                      // minLength={5}
                      />
                    </div>
                  </div>
                  <div className={"col-md-3"}>
                    <div className="form-group">
                      <Label className="form-label">State <span style={{ color: "red" }}>*</span></Label>
                      <select
                        name={"to_state"}
                        className="form-select"
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                        required={State.getAllStates().filter(el => el.countryCode === country).length > 0 ? true : false}
                      >
                        <option disabled={true} value={""}>Select a state</option>
                        {State.getAllStates().sort((a, b) => a.name.localeCompare(b.name)).filter(el => el.countryCode === country).map((part, id) => {
                          return (<option key={id} value={part.isoCode}>{part.name}</option>);
                        })};
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <button type={"submit"} className={"btn btn-success btn-sm"} style={{ float: "right" }}>Save</button>
              <br />
              <br />
            </form>
          </ModalBody>
        </div>
      </Modal>
      {/* Add Modal */}
      <Modal
        isOpen={addModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAddModal(!addModal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setAddModal(!addModal);
            }}
          >
            Disable User
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleAddUser}>

              <div className="mt-3 mb-3">
                <Label className="form-label">Users</Label>
                <Select

                  isMulti
                  options={users}

                  onChange={(selectedGroup) => {
                    const selectedUserIds = selectedGroup ? selectedGroup.map(user => user.value) : [];
                    console.log(selectedUserIds)
                    setUserId(selectedUserIds);

                  }}

                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
              <button className="btn btn-primary w-md mt-3" type="submit">Add</button>
            </form>
          </ModalBody>

        </div>
      </Modal>

      {/* {View Modal} */}
      <Modal
        isOpen={viewModal}
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggleViewModal}>
        <ModalHeader toggle={toggleViewModal}>Disabled User</ModalHeader>

        <ModalBody style={{ padding: '20px', backgroundColor: '#f7f7f7', borderTop: '1px solid #dee2e6' }}>
          {Array.isArray(assignedUserEmail) && assignedUserEmail.length > 0 ? (
            <div>
              {assignedUserEmail.map(id => {
                const user = users.find(u => u.value === id);
                return (
                  <div key={id} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ margin: 0 }}>Email: {user ? user.label : "-"}</p>
                    <button className={"btn btn-danger btn-sm"} title={"Remove User"} onClick={() => handleDeleteUser(user.value)}><i className={"bx bx-x"}></i></button>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>Disabled users not found.</p>
          )}
        </ModalBody>
        {/* <ModalFooter  >
            <Button color="secondary" onClick={toggleViewModal}>Close</Button>
          </ModalFooter > */}
      </Modal >
      {/*Modal*/}
    </div>
  );
}
ApiSettings.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default ApiSettings;